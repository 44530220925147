import React from "react";
import { Grid } from "@material-ui/core";
// reactstrap components
import { Card, CardHeader, CardBody, CardTitle, Table } from "reactstrap";
import CustomTextButton from "../Buttons/TextButton";

const CustomTable = ({
    title = "",
    columns = [],
    data = [],
    onRowClick,
    exportable = false,
    emptyText = "No data available",
    showViewMoreButton = false,
    viewMoreButtonClick,
}) => {
    return (
        <div className="content">
            <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h5">{title}</CardTitle>
                        </CardHeader>
                        <CardBody>
                            {data.length ? (
                                <>
                                    {" "}
                                    <Table responsive hover>
                                        <thead>
                                            <tr>
                                                {columns.map((e, i) => {
                                                    return <th key={i}>{e.title}</th>;
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((e, i) => {
                                                return (
                                                    <tr key={i}>
                                                        {columns.map((value, index) => {
                                                            return (
                                                                <td
                                                                    key={index}
                                                                    onClick={() => (onRowClick ? onRowClick(e) : null)}
                                                                >
                                                                    {value.render ? value.render(e) : e[value.field] ?? ""}
                                                                </td>
                                                            );
                                                        })}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                    <div style={{ display: "flex", justifyContent: "center", padding: "1em" }}>
                                        {showViewMoreButton ? (
                                            <CustomTextButton
                                                color="secondary"
                                                variant="outlined"
                                                title="View More"
                                                onClick={viewMoreButtonClick}
                                                size="small"
                                            />
                                        ) : null}
                                    </div>
                                </>
                            ) : (
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <h4 style={{ fontSize: "15px", fontWeight: "bold", fontFamily: "inherit" }}>
                                        {emptyText}
                                    </h4>
                                </div>
                            )}
                        </CardBody>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default CustomTable;
