/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Box, Card, Container, Grid, Stack, Button, OutlinedInput } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { resetAllCreatorNfts, listAllCreatorNfts } from "store/actions";
import NavBar from "views/landing/navBar";
import Loader from "components/Loader";
import { Icon } from "@iconify/react";
import PageRouteNavigator from "views/landing/pageRouteNavigator";
import EventsList from "./EventsList";

export function NftListOfCreator() {
    const { username } = useParams();
    const dispatch = useDispatch();
    const [search, setSearch] = useState("");

    const [page, setPage] = useState(1);

    const { creatorNftList, eventName, loading, showMore } = useSelector(
        ({
            landing: {
                creatorNftList: { list, event, showViewMore },
            },
            loading,
        }) => ({
            creatorNftList: list,
            eventName: event,
            loading,
            showMore: showViewMore,
        })
    );

    const queryParameters = new URLSearchParams(window.location.search);
    const event = queryParameters.get("event");
    const certificate = queryParameters.get("certificate");

    useEffect(() => {
        if (event) {
            if (certificate) {
                window.open(
                    `https://verified.certify.social/organisation/${username}?event=${
                        event ?? "tiecon-kerala2022"
                    }&certificate=${certificate}`,
                    "_self"
                );
            } else {
                setPage(2);
                dispatch(resetAllCreatorNfts());
                fetchMoreNftsList();
            }
        }
    }, []);

    const fetchMoreNftsList = () => {
        dispatch(listAllCreatorNfts({ username: username, text: search, event: event }));
    };

    const handleClick = (art) => {
        window.open(
            `https://verified.certify.social/organisation/${username}?event=${
                art?.event_id?.user_name ?? "tiecon-kerala2022"
            }&certificate=${art.nft_id}`,
            "_self"
        );
    };

    const handleSearch = () => {
        dispatch(resetAllCreatorNfts());
        fetchMoreNftsList();
    };

    return (
        <>
            <Loader visible={loading} />
            <NavBar />

            {page === 1 ? (
                <EventsList orgName={username} />
            ) : page === 2 ? (
                <Box sx={{ width: "100%", color: "#fff" }}>
                    <Container>
                        <Stack sx={{ minHeight: "100vh", p: "40px 0px", pt: "100px" }}>
                            <Grid
                                container
                                spacing={{lg:5, xs:3}}
                                alignItems="center"
                                justifyContent={{ lg: "flex-start", md: "center", sm: "center", xs: "center" }}
                            >
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <PageRouteNavigator
                                        routes={[
                                            { name: "organisations", path: "/organisations" },
                                            { name: "events", path: `/organisation/${username}` },
                                            { name: "certificates", path: "", isActive: true },
                                        ]}
                                    />
                            
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Stack direction={{lg:"row", xs:"column"}} justifyContent="space-between" spacing={2}>
                                    {eventName ?
                                    <Stack sx={{ mt: 1, fontSize: "20px", color: "#B5EAD7" }} alignItems={{ lg: "flex-start", xs: "center" }}>
                                        <b>{eventName}</b>
                                    </Stack>
                                   : null}
                                    <Stack
                                        direction="row"
                                        justifyContent={{ lg: "flex-start", xs: "center" }}
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        <OutlinedInput
                                            size="small"
                                            placeholder="Search"
                                            sx={{ border: "2px solid #fff", color: "#fff", fontSize: "bold" }}
                                            onChange={(e) => setSearch(e.target.value)}
                                        />
                                        <Button variant="contained" onClick={handleSearch}>
                                            <Icon icon="material-symbols:search" width={30} />
                                        </Button>
                                    </Stack>
                                    
                                    </Stack>
                                </Grid>
                                {creatorNftList
                                    ? creatorNftList.map((arts, index) => (
                                          <Grid item lg={3} md={4} sm={6} xs={10} key={index}>
                                              <Stack alignItems="center">
                                                  <Card sx={{ width: "250px", borderRadius: 2 }}>
                                                      <Stack
                                                          sx={{ p: 2 }}
                                                          spacing={1}
                                                          alignItems="center"
                                                          title={arts?.name}
                                                      >
                                                          <Box
                                                              width={"100%"}
                                                              height={200}
                                                              sx={{
                                                                  borderRadius: 2,
                                                                  border: "1px solid #000",
                                                                  backgroundColor: "#fff",
                                                                  overflow: "hidden",
                                                              }}
                                                          >
                                                              <img
                                                                  alt="certificate image..."
                                                                  width={"100%"}
                                                                  height={"100%"}
                                                                  src={arts?.art_url}
                                                              />
                                                          </Box>
                                                          <Box width={"100%"}>
                                                              <Stack alignItems="center" spacing={1}>
                                                                  <Stack sx={{ width: "100%" }}>
                                                                      <h4
                                                                          style={{
                                                                              display: "inline-block",
                                                                              whiteSpace: "nowrap",
                                                                              overflow: "hidden",
                                                                              textOverflow: "ellipsis",
                                                                              textAlign: "center",
                                                                          }}
                                                                      >
                                                                          {arts?.name}
                                                                      </h4>
                                                                  </Stack>
                                                                  <Button
                                                                      variant="contained"
                                                                      size="small"
                                                                      fullWidth
                                                                      style={{ backgroundColor: "#B5EAD7", color: "#000" }}
                                                                      onClick={() => handleClick(arts)}
                                                                  >
                                                                      view &nbsp;
                                                                      <i className="fas fa-arrow-right" />
                                                                  </Button>
                                                              </Stack>
                                                          </Box>
                                                      </Stack>
                                                  </Card>
                                              </Stack>
                                          </Grid>
                                      ))
                                    : null}
                            </Grid>
                            <br />
                            {showMore ? (
                                <Grid container justifyContent="center">
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            style={{ backgroundColor: "#B5EAD7", color: "#000" }}
                                            onClick={() => fetchMoreNftsList()}
                                        >
                                            <b>View More</b>
                                        </Button>
                                    </Grid>
                                </Grid>
                            ) : null}
                        </Stack>
                    </Container>
                </Box>
            ) : null}
        </>
    );
}
