import React, { useEffect } from "react";
import "./style.css";

import NavBar from "./navBar";
import BannerContent from "./banner-content";
// import FeaturesSection from "./features-section";
// import SupportingStartups from "./supporting-startups";
// import PaymentScreen from "./payment-screen";
import Footer from "./footer";
import Benefits from "./benefits";
import UseCases from "./useCases";
import HowItWorks from "./howItWorks";
import RegisterEmail from "./registerEmail";
import FirstInIndia from "./firstInIndia";
import PartnersLogoList from "./partners-logo-list";
import PostSection from "./post-section";
import OPENquoteSection from "./OPEN-quote-section";

function Landing() {

    useEffect(() => {
        document.body.style.background = "linear-gradient(#000, #222831, #000)";
    }, []);

    return (
        <div>
            <NavBar />
            <BannerContent />
            <PartnersLogoList />
            <OPENquoteSection />
            <Benefits />
            <FirstInIndia />
            <PostSection />
            <UseCases />
            <RegisterEmail />
            <HowItWorks />
            {/* <FeaturesSection /> */}
            {/* <SupportingStartups />
            <PaymentScreen /> */}
            <Footer />
        </div>
    );
}

export default Landing;
