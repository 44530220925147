import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Tab, Tabs } from '@material-ui/core';
import GetPrivateKey from './getPrivateKey';
import { useDispatch } from 'react-redux';
import SetupPhantomWallet from './setupPhantomWallet';
import ConnectToInstagram from './connectToInstagram';

export default function SocialTab() {
    const dispatch = useDispatch();
    const [tab, setTab] = useState(0);

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <>
            <Box sx={{ borderBottom: "1px solid #5c5c5c", borderColor: 'divider' }}>
                <Tabs 
                    value={tab} 
                    onChange={handleChange} 
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab label="1. get private key" />
                    <Tab label="2. set up Phantom wallet" />
                    <Tab label="3. connect to instagram" />
                </Tabs>
            </Box>
            <TabPanel value={tab} index={0}>
                <GetPrivateKey dispatch={dispatch} setTab={setTab}/>
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <SetupPhantomWallet setTab={setTab}/>
            </TabPanel>
            <TabPanel value={tab} index={2}>
                <ConnectToInstagram setTab={setTab}/>
            </TabPanel>
        </>
    )
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <>{children}</>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};