import React, { useEffect, useState } from 'react';
import { Box, Button, OutlinedInput, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { sendOtp } from 'store/actions';
import { getPrivateKey } from 'store/actions';
import { toast } from 'react-toastify';
import { listUserProfile } from 'store/actions';
import { Warning } from '@material-ui/icons';

function GetPrivateKey({ dispatch, setTab }) {
    const [page, setPage] = useState(1);
    const [otp, setOtp] = useState("");
    const [prKey, setPrKey] = useState("");
    const [copied, setCopied] = useState(false);

    const { profileData , token } = useSelector(({ users: { profileData, privateKeyToken } }) => ({
        token: privateKeyToken,
        profileData,
    }));

    useEffect(() => {
        dispatch(listUserProfile());
        if (token) {
            let tok = atob(token);
            try {
                let obj = JSON.parse(tok);
                setPrKey(obj.pr_key);
            }
            catch {
                setPage(2);
                return toast.error("Failed to get private key!, try again.")
            }
        }
    // eslint-disable-next-line
    },[token])

    return (
        <>
            {page === 1 ?
            <Box sx={{ color: "#B5EAD7" }}>
                <h4 style={{fontWeight:"500", textAlign: "center"}}>If you want to list the NFTs on Meta platforms, you have to do the following steps. </h4>
                <Stack sx={{ minHeight: "300px" }} alignItems="center" justifyContent="center">
                    <Button 
                        variant="contained"
                        style={{ backgroundColor: "#B5EAD7", color: "#000" }}
                        onClick={async() => {
                            await dispatch(sendOtp({email: profileData?.email, isForPrKey: true}));
                            setPage(2);
                        }}
                    >
                        view private key &nbsp;<i className='fas fa-long-arrow-alt-right'/>
                    </Button>
                </Stack>
                <Stack alignItems="center" sx={{border: "1px solid #B5EAD7", p:2, backgroundColor: "rgb(0, 0, 0, 0.3)"}}>
                    <Typography>
                        <b>Note:</b> We will send you an email with OTP to your registered email ID <b>{profileData?.email ?? ""}</b>
                    </Typography>
                </Stack>
            </Box> :
            page === 2 ?
            <Box>
                <Stack sx={{ minHeight: "300px" }} alignItems="center" justifyContent="center" spacing={2}>
                    <Typography color="#fff" variant="body2">
                        enter OTP sent to <b>{profileData?.email ?? "your email"}</b>
                    </Typography>
                    <OutlinedInput
                        placeholder="OTP"
                        sx={{ border: "2px solid #B5EAD7", color: "#fff", fontSize: "bold" }}
                        onChange={(e) => setOtp(e.target.value)}
                    />
                    <Button 
                        variant="contained"
                        style={{ backgroundColor: "#B5EAD7", color: "#000" }}
                        disabled={!otp}
                        onClick={async() => {
                            await dispatch(getPrivateKey({otp: otp}));
                            setPage(3);
                        }}
                    >
                        view private key &nbsp;<i className='fas fa-long-arrow-alt-right'/>
                    </Button>
                </Stack>
                <Stack alignItems="center" sx={{border: "1px solid #B5EAD7", p:2, backgroundColor: "rgb(0, 0, 0, 0.3)"}}>
                    <Typography color="#B5EAD7">
                        <b>Note:</b> Keep this private key handy as you will need to paste this
                        after you install the Phantom wallet in the next step
                    </Typography>
                </Stack>
            </Box> :
            page === 3 ?
            <Box>
                <Stack spacing={2}>
                <Stack sx={{ minHeight: "300px" }} alignItems="center" justifyContent="center" spacing={2}>
                    <OutlinedInput
                        value={prKey}
                        sx={{ border: "2px solid #B5EAD7", color: "#fff", fontSize: "bold" }}
                    />
                    <Button 
                        variant="contained"
                        style={{ backgroundColor: "#B5EAD7", color: "#000" }}
                        onClick={() => {
                            setCopied(true);
                            setTimeout(() => setCopied(false), 5000);
                            navigator.clipboard.writeText(prKey);
                        }}
                    >
                        {copied ? "copied" : "copy"} private key &nbsp;<i className="fa fa-copy"/>
                    </Button>
                </Stack>
                <Stack 
                    direction="row" 
                    alignItems="center" 
                    justifyContent="center" 
                    spacing={2} 
                    sx={{border: "1px solid #B5EAD7", p:2, backgroundColor: "rgb(0, 0, 0, 0.3)"}}
                >
                    <Warning color='primary' fontSize={"large"}/>
                    <Typography color="#B5EAD7">
                     <b>Note:</b> Copy and save this private key as you will need it in the next step to connect with Phantom Wallet
                    </Typography>
                </Stack>
                <Stack alignItems="flex-end">
                    <Button
                        variant="contained"
                        style={{ backgroundColor: "#ccc", color: "#000" }}
                        onClick={() => setTab(1)}
                    >
                        <b>Next</b>
                    </Button>
                </Stack>
                </Stack>
            </Box> :
            null }
        </>
    )
}

export default GetPrivateKey