import React from "react";

function Footer() {
  return (
    <>
      <div className="copy">
        <div className="container">
          <a href="/">2022 © All Rights Reserved | certify.social</a>
          <span>
            {/* <a href="#">
              <i className="fab fa-discord" />
            </a>
            <a href="#">
              <i className="fab fa-telegram" />
            </a>
            <a href="">
              <i className="fab fa-whatsapp" />
            </a> */}
            <a href="https://www.facebook.com/certify.social">
              <i className="fab fa-facebook-f" />
            </a>
            <a href="https://twitter.com/certifysocial">
              <i className="fab fa-twitter" />
            </a>
            <a href="https://www.instagram.com/certify.social/">
              <i className="fab fa-instagram" />
            </a>
            <a href="https://www.linkedin.com/company/certify-social">
              <i className="fab fa-linkedin" />
            </a>
            <a href="/about-us">About us</a>
            {/* <p onClick={() => handleHome(false)}>About Us</p> */}
          </span>
        </div>
      </div>
    </>
  );
}

export default Footer;
