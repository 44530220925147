/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Container, Card, Jumbotron } from "reactstrap";
import { Grid } from "@material-ui/core";
//styles
import "./img-styles.css";

import CustomBackButton from "components/Custom/Buttons/BackButton";
import { listNftDetails, resetNftDetailsData } from "store/actions";
import CustomModal from "components/Custom/Modal";
import CustomTextButton from "components/Custom/Buttons/TextButton";
import DeleteDialogBox from "./deleteDialogBox";
import ShareNft from "./shareNft";
import { toast } from "react-toastify";
import { Button, Stack } from "@mui/material";
import { RWebShare } from "react-web-share";

const NftDetails = () => {
    const dispatch = useDispatch();
    const { nftId } = useParams();

    const [showImg, setShowImg] = useState(true);
    const [showDialogBox, setShowDialogBox] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const { nftDetails, funqCreditsData } = useSelector(({ nfts: { details }, users: { funqCredits } }) => ({
        nftDetails: details,
        funqCreditsData: funqCredits,
    }));
    
    useEffect(() => {
        dispatch(resetNftDetailsData());
        dispatch(listNftDetails({ id: nftId }));
    }, []);

    const handleModal = () => {
        setShowModal(false);
    };

    const modalContent = () => {
        if (showImg) {
            return (
                <div style={{ padding: "1em", display: "flex", justifyContent: "center" }}>
                    <img src={nftDetails?.art_url} />
                </div>
            );
        } else {
            return <ShareNft Id={nftDetails?._id} nftId={nftDetails?.nft_id} handleModal={handleModal} />;
        }
    };

    // const handleShareButton = () => {
    //     if (!funqCreditsData?.is_org) {
    //         return toast.info("There is no access to the share certificate for you");
    //     }
    //     if (nftDetails?.is_for_sale) {
    //         return toast.info("Not able to transfer certificate added to sales");
    //     }

    //     if (nftDetails?.status === "sold") {
    //         return toast.info("Not able to transfer sold certificate");
    //     }

    //     if (nftDetails?.status === "pending") {
    //         return toast.info("Not able to transfer pending certificate");
    //     }

    //     if (nftDetails?.status === "transferred") {
    //         return toast.info("Not able to share transferred certificate");
    //     }

    //     setShowImg(false);
    //     setShowModal(true);
    // };

    const handleDialogClose = () => {
        setShowDialogBox(false);
    };

    return (
        <>
            <Container fluid>
                <Grid container>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Card>
                            <div style={{ padding: "1em" }}>
                                <Grid container>
                                    <Grid item>
                                        <CustomBackButton />
                                    </Grid>
                                </Grid>
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <Container fluid>
                <Grid container spacing={2}>
                    <Grid item xl={3} lg={3} md={3} xs={12} sm={12} className="container">
                        <img src={nftDetails?.art_url} className="image" style={{ width: "100%" }} />
                        <div className="middle">
                            <div
                                className="text"
                                onClick={() => {
                                    setShowImg(true);
                                    setShowModal(true);
                                }}
                            >
                                View Original
                            </div>
                        </div>
                    </Grid>
                    <Grid item xl={9} lg={9} md={9} xs={12} sm={12}>
                        <Jumbotron style={{ height: "100%", marginBottom: 10 }}>
                            <h1 className="display-5">{nftDetails?.name}</h1>
                            <p className="lead">{nftDetails?.description}</p>
                            <hr className="my-2" />
                            <p style={{ wordWrap: "break-word" }}>NFT ID: {nftDetails?.nft_id}</p>
                            <p>Owner: {nftDetails?.owner_id?.name}</p>
                            {/* <p>Royalties: {nftDetails?.royalty_percentage}%</p> */}
                            <p>Status: {nftDetails?.status}</p>
                            {nftDetails?.shared_to ? <p>Transferred to: {nftDetails?.shared_to}</p> : null}
                            <>
                                <Grid container spacing={1} justifyContent="flex-start">
                                    {/* {funqCreditsData?.is_org ?
                                    <Grid item>
                                        <CustomTextButton title="Share Certificate" onClick={() => handleShareButton()} />
                                    </Grid>
                                    : null} */}
                                    {/* <Grid item>
                                        <CustomTextButton
                                            title="Delete"
                                            onClick={() => setShowDialogBox(true)}
                                            disabled={
                                                nftDetails.status === "transferred" ||
                                                nftDetails.status === "for sales" ||
                                                nftDetails.status === "sold"
                                            }
                                        />
                                    </Grid> */}
                                </Grid>
                            </>
                            <p className="lead"></p>
                        </Jumbotron>
                        <Stack direction={{lg: "row", md: "row", sm: "row", xs: "column"}} spacing={1}>
                            <Button
                                fullWidth
                                variant="contained"
                                style={{ backgroundColor: "#B5EAD7", color: "#000" }}
                                onClick={() =>
                                    window.open(
                                        `https://explorer.solana.com/address/${nftDetails?.nft_id}?cluster=mainnet-beta`
                                    )
                                }
                            >
                                <b>View on blockchain</b>
                            </Button>
                            <RWebShare
                                data={{
                                    // text: `https://verified.certify.social/organisation/${username}?event=tiecon-kerala2022&certificate=${data?.nft_id}`,
                                    url: `https://verified.certify.social/organisation/${nftDetails?.creator_id?.user_name}?event=${nftDetails?.event_id?.user_name}&certificate=${nftDetails?.nft_id}`,
                                    title: nftDetails?.name ?? "Certificate",
                                }}
                                onClick={() => console.log("shared successfully!")}
                            >
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    style={{ borderColor: "#B5EAD7", color: "#B5EAD7" }}
                                >
                                    <b>Share on social media</b>
                                </Button>
                            </RWebShare>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
            <CustomModal
                title={showImg ? "" : "Share Certificate"}
                visible={showModal}
                content={modalContent()}
                handleClose={() => setShowModal(false)}
            />
            <DeleteDialogBox open={showDialogBox} handleClose={handleDialogClose} nftId={nftId} dispatch={dispatch} />
        </>
    );
};

export default NftDetails;
