import React from 'react'
import { Grid, Link, Stack, Typography } from '@mui/material'
import openLogo from "./../../assets/img/partners-logos/open-logo.svg"

function OPENquoteSection() {
    return (
        <>
            <section style={{ backgroundColor: "#000", marginBottom: "2px" }}>
                <div className="container">
                    <Grid container justifyContent={"center"} p={{xs: 2, lg: 0}}>
                        <Grid item lg={6} md={6} sm={8} xs={12}>
                            <Stack height={"100%"} p={2} spacing={2} color={"#000"} bgcolor={"#fff"}>
                                <img src={openLogo} width={"30%"} alt=""/>
                                <Typography fontSize={20}>
                                <b>Fintech Unicorn OPEN launched rewards & recognition using NFTs</b>
                                </Typography>
                                <Typography fontSize={15} fontWeight={600} color="#444">
                                <i>“At OPEN, we were looking to do something innovative in our Rewards & Recognition and hence used NFTs to give out the certificates.</i>
                                </Typography>
                                <Typography fontSize={15} fontWeight={600} color="#444">
                                <i>Thanks to the Certify Social team for working with us over the weekend to ensure we have it in time for Monday.”</i>
                                </Typography>
                                <Typography fontSize={14}>
                                <b>- Mabel Chacko, Co-founder and COO of OPEN</b>, Asia's largest growing neo-banking platform for SMBs and startups
                                </Typography>
                                <Link target="_blank" rel="noopener" href="https://medium.com/@certifysociLinkl/fintech-unicorn-open-issues-nft-certificates-for-employees-and-teams-5f8ed389cf95">
                                    {"Read more >>"}
                                </Link>
                            </Stack>
                        </Grid>
                        <Grid item lg={4} md={6} sm={3} xs={12}>
                            <Stack>
                                <img src='https://financialallianceforwomen.org/wp-content/uploads/2021/09/Mabel_Chacko-1.jpg' alt=''/>
                            </Stack>
                        </Grid>
                    </Grid>
                </div>
            </section>
        </>
    )
}

export default OPENquoteSection