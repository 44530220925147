import { LIST_ALL_TXNS, RESET_ALL_TXNS, TOGGLE_TXNS_VIEW_MODE } from "../types";

const INITIAL_STATE = {
    list: [],
    showViewMore: false,
    isCardView: true,
    pageNumber: 1,
    pageSize: 10,
    details: {},
};

const txnReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case LIST_ALL_TXNS:
            return {
                ...state,
                pageSize: 10,
                ...payload,
            };
        case TOGGLE_TXNS_VIEW_MODE:
            return {
                ...state,
                isCardView: payload,
            };
        case RESET_ALL_TXNS:
            return INITIAL_STATE;

        default:
            return state;
    }
};

export default txnReducer;
