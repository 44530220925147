import React from "react";
import { Box, Grid, Stack } from "@mui/material";
import smaramLogo from "./../../assets/img/partners-logos/smaram-logo.svg";
import zimyoLogo from "./../../assets/img/partners-logos/zimyo-logo.png";
import openLogo from "./../../assets/img/partners-logos/open-logo.svg";
import kmaLogo from "./../../assets/img/partners-logos/kma-logo.png";
import nasscomLogo from "./../../assets/img/partners-logos/nasscom-logo.png";
import geojitLogo from "./../../assets/img/partners-logos/geojit-logo.png";
import kdwLogo from "./../../assets/img/partners-logos/kdw-logo.png";

function PartnersLogoList() {
    const partnersData = [
        {
            icon: "https://i.ibb.co/hYR7F6w/Untitled-presentation-39.png",
            title: "KSUM",
        },
        {
            icon: "https://i.ibb.co/hdKvf73/Untitled-presentation-41.png",
            title: "TiE Kerala",
        },
        {
            icon: "https://i.ibb.co/3YPhzSZ/muthoot-finance.png",
            title: "Muthoot Finance",
        },
        {
            icon: kdwLogo,
            title: "Kochi design week",
        },
        {
            icon: openLogo,
            title: "OPEN",
            noSpace: true,
        },
        {
            icon: zimyoLogo,
            title: "Zimyo",
            noSpace: true,
        },
        {
            icon: smaramLogo,
            title: "Smaram",
            noSpace: true,
        },
        {
            icon: kmaLogo,
            title: "KMA",
        },
        {
            icon: nasscomLogo,
            title: "NASSCOM",
            noSpace: true,
        },
        {
            icon: geojitLogo,
            title: "Geojit",
        },
    ];

    return (
        <>
            <Box sx={{ p:2, backgroundColor: "#fff", color: "#000" }}>
                <div className="container">
                    <Box sx={{ width: "100%" }}>
                        <Stack spacing={2}>
                            <h5 style={{ textAlign: "center" }}>
                            Certify Social is proud to partner with awesome forward thinking organisations to issue certificates as NFTs
                            </h5>
                            <Stack>
                                <Grid container spacing={1} justifyContent="center">
                                    {partnersData.map((e, i) => (
                                        <Grid item lg={2} md={6} xs={6} key={i}>
                                            <Stack spacing={2} height={"100%"} padding={e.noSpace ? 4 : 0} justifyContent="center" alignItems="center">
                                                <Box>
                                                    <img src={e.icon} width={220} alt=""/>
                                                </Box>
                                            </Stack>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Stack>
                        </Stack>
                    </Box>
                </div>
            </Box>
        </>
    );
}

export default PartnersLogoList;
