/*eslint-disable*/
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Card, Container, Grid, Stack, Button } from "@mui/material";

import NavBar from "views/landing/navBar";
import PageRouteNavigator from "views/landing/pageRouteNavigator";
import { resetAllEventsList, listAllEventsList } from "store/actions";

function EventsList({ orgName = "" }) {
    const dispatch = useDispatch();

    const { data, org_name, showViewMore } = useSelector(
        ({
            landing: {
                eventsList: { list, org_name, showViewMore },
            },
        }) => ({
            data: list,
            org_name,
            showViewMore,
        })
    );

    useEffect(() => {
        dispatch(resetAllEventsList());
        fetchMoreData();
    }, []);

    const fetchMoreData = () => {
        dispatch(listAllEventsList({ orgName: orgName }));
    };

    return (
        <>
            <NavBar />
            <Box sx={{ width: "100%", color: "#fff" }}>
                <Container>
                    <Stack sx={{ minHeight: "100vh", p: "40px 0px", pt: "100px" }}>
                        <Grid
                            container
                            spacing={5}
                            alignItems="center"
                            justifyContent={{ lg: "flex-start", md: "center", sm: "center", xs: "center" }}
                        >
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <PageRouteNavigator
                                    routes={[
                                        { name: "organisations", path: "/organisations", isActive: false },
                                        { name: "events", path: "", isActive: true },
                                    ]}
                                />
                                {org_name ?
                                    <Stack sx={{ mt: 1, fontSize: "20px", color: "#B5EAD7" }} alignItems={{ lg: "flex-start", xs: "center" }}>
                                        <b>{org_name}</b>
                                    </Stack>
                                : null}
                            </Grid>
                            {data ? (
                                <>
                                    {data.map((creator, index) => (
                                        <Grid item lg={3} md={4} sm={6} xs={10} key={index} title={creator?.name}>
                                            <Stack alignItems="center">
                                                <Card sx={{ width: "250px" }}>
                                                    <Stack sx={{ p: 2 }} spacing={1} alignItems="center">
                                                        <Box
                                                            width={"100%"}
                                                            height={200}
                                                            sx={{
                                                                p: 2,
                                                                borderRadius: 2,
                                                                backgroundColor: "#fff",
                                                                border: "1px solid #000",
                                                            }}
                                                        >
                                                            <img
                                                                alt="..."
                                                                width={"100%"}
                                                                height={"100%"}
                                                                src={
                                                                    creator?.img ??
                                                                    require("assets/img/user/profile-avatar.svg").default
                                                                }
                                                            />
                                                        </Box>
                                                        <Box width={"100%"}>
                                                            <Stack alignItems="center" spacing={1}>
                                                                <Stack sx={{ width: "100%" }}>
                                                                    <h4
                                                                        style={{
                                                                            display: "inline-block",
                                                                            whiteSpace: "nowrap",
                                                                            overflow: "hidden",
                                                                            textOverflow: "ellipsis",
                                                                            textAlign: "center",
                                                                        }}
                                                                    >
                                                                        <b>{creator?.name}</b>
                                                                    </h4>
                                                                </Stack>
                                                                {/* <Stack>{creator?.email}</Stack> */}
                                                                <Button
                                                                    variant="contained"
                                                                    size="small"
                                                                    fullWidth
                                                                    style={{ backgroundColor: "#B5EAD7", color: "#000" }}
                                                                    onClick={() =>
                                                                        window.open(
                                                                            `/organisation/${orgName}?event=${creator?.user_name}`,
                                                                            "_self"
                                                                        )
                                                                    }
                                                                >
                                                                    view &nbsp;
                                                                    <i className="fas fa-arrow-right" />
                                                                </Button>
                                                            </Stack>
                                                        </Box>
                                                    </Stack>
                                                </Card>
                                            </Stack>
                                        </Grid>
                                    ))}
                                </>
                            ) : (
                                <Stack justifyContent="center" alignItems="center" sx={{ color: "#fff" }}>
                                    No data found
                                </Stack>
                            )}
                        </Grid>
                    </Stack>
                </Container>
            </Box>
        </>
    );
}

export default EventsList;
