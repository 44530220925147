import React, { useState } from "react";

import { Grid, Tab, Box } from "@material-ui/core";
import { TabContext, TabList } from "@material-ui/lab";
import ToEmail from "./toEmail";
import ToWallet from "./toWallet";

const ShareNft = ({ Id, nftId, handleModal }) => {
    const [value, setValue] = useState("1");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <Grid container justifyContent="center" style={{ padding: "1em" }}>
                <Grid item>
                    <Box sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="To email" value="1" />
                                    <Tab label="To wallet" value="2" />
                                </TabList>
                            </Box>
                        </TabContext>
                    </Box>
                </Grid>
            </Grid>
            <Grid item>
                {value === "1" ? (
                    <ToEmail Id={Id} handleModal={handleModal} />
                ) : (
                    <ToWallet nftId={nftId} handleModal={handleModal} />
                )}
            </Grid>
        </div>
    );
};

export default ShareNft;
