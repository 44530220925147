import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { Icon } from "@iconify/react";

function Benefits() {
    const benefitsData = [
        {
            icon: "material-symbols:handshake",
            title: "Trustable",
            description: "Since it is decentralised. So more than a paper or image/PDFs, thus providing more trustability",
        },
        {
            icon: "codicon:workspace-trusted",
            title: "Veriﬁable",
            description: "Since it is always there on the blockchain. So never lose it, as it is permanent",
        },
        {
            icon: "clarity:wallet-solid",
            title: "Valuable",
            description: "NFT-ising creates higher perceived value than usually issued certiﬁcate",
        },
        {
            icon: "bxs:user-check",
            title: "Social",
            description:
                "Making certiﬁcates cool and social for the youth. Meta (Instagram, Facebook) has launched a new feature identifying NFTs and we are compatible",
        },
    ];

    return (
        <>
            <section style={{ backgroundColor: "#000" }}>
                <div className="container">
                    <Box sx={{ width: "100%" }}>
                        <Stack spacing={7}>
                            <Typography variant="h5" sx={{ textAlign: "center" }}>
                                B E N E F I T S
                            </Typography>
                            <Stack>
                                <Grid container spacing={4} justifyContent="center">
                                    {benefitsData.map((e, i) => (
                                        <Grid item lg={4} md={6} xs={10} key={i}>
                                            <Stack direction="row" spacing={2} alignItems="center">
                                                <Box sx={{ width: "70px" }}>
                                                    <Icon icon={e.icon} width="60" color="#B5EAD7" />
                                                </Box>
                                                <Stack spacing={1}>
                                                    <div style={{ color: "#fff" }}>
                                                        <h5>{e.title}</h5>
                                                    </div>
                                                    <Typography variant="body1">{e.description}</Typography>
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Stack>
                        </Stack>
                    </Box>
                </div>
            </section>
        </>
    );
}

export default Benefits;
