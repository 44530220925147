var jsZip = require("jszip");

export const jsZipToBase64 = (data, filename) => {
    return new Promise((resolve, reject) => {
        jsZip.loadAsync(data).then(function (zip) {
            zip.forEach((relPath, file) => {
                if (relPath === filename) {
                    let extension = filename.split(".").pop();
                    file.async("base64").then((img) => {
                        return resolve(`data:image/${extension};base64,${img}`);
                    });
                }
            });
        });
    });
};
