import React from "react";
import { CircularProgress, Backdrop } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

const Loader = ({ visible }) => {
    const theme = useTheme();
    return (
        <Backdrop open={visible} style={{ zIndex: 999 }}>
            <CircularProgress style={{ color: theme.palette.primary.main }} />
        </Backdrop>
    );
};

export default Loader;
