import React from 'react';
import { Box, Stack } from '@mui/material';
import { Button, Chip } from '@material-ui/core';
import { history } from 'utils';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay,EffectFlip, Pagination, Navigation } from "swiper";

import "./post-section.css";
import 'swiper/swiper.min.css';

function PostSection() {
    return (
        <>
            <section style={{ backgroundColor: "#000", color: "#fff", marginTop: "1px" }}>
                <div className="container">
                    <Box sx={{ width: "100%", padding: "30px 0" }}>
                        <div>
                            <Stack spacing={4}>
                                <Stack spacing={{ xs: 4, lg: 3 }} direction={{ lg: "row", xs: "column" }} alignItems="center">
                                    <Stack spacing={{ xs: 4, lg: 3 }} style={{ width: "100%" }}>
                                        <Stack alignItems={{ xs: "center", lg: "flex-start" }} sx={{ fontSize: { lg: 20, xs: 10 } }}>
                                            <h1>
                                                <b>2000+</b> users love Certify Social
                                            </h1>
                                        </Stack>
                                        <Stack alignItems={{ xs: "center", lg: "flex-start" }}>
                                            <Button
                                                style={{ backgroundColor: "#B5EAD7", padding: "10px 30px" }}
                                                variant="contained"
                                                onClick={() => history.push("/schedule")}
                                            >
                                                <b>Schedule meeting</b>
                                            </Button>
                                        </Stack>
                                    </Stack>
                                    <Stack alignItems="center">
                                        <Swiper
                                            autoplay={{
                                                delay: 5000,
                                                disableOnInteraction: false,
                                            }}
                                            centeredSlides={true}
                                            effect={"cards"}
                                            grabCursor={true}
                                            pagination={{
                                                clickable: true,
                                              }}
                                            navigation={true}
                                            modules={[Autoplay, EffectFlip, Pagination, Navigation]}
                                            className="mySwiper"
                                        >
                                            <SwiperSlide>
                                                <Box sx={{ width: { lg: 400, xs: "100%" } }}>
                                                    <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7005822951262494721" height="506" width="100%" frameBorder="0" allowFullScreen="" title="Embedded post"></iframe>
                                                </Box>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <Box sx={{ width: { lg: 400, xs: "100%" } }}>
                                                    <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7008288080759570432" height="506" width="100%" frameBorder="0" allowFullScreen="" title="Embedded post"></iframe>
                                                </Box>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <Box sx={{ width: { lg: 400, xs: "100%" } }}>
                                                    <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7009859104454189056" height="506" width="100%" frameBorder="0" allowFullScreen="" title="Embedded post"></iframe>
                                                </Box>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <Box sx={{ width: { lg: 400, xs: "100%" } }}>
                                                    <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7009808877068201984" height="506" width="100%" frameBorder="0" allowFullScreen="" title="Embedded post"></iframe>
                                                </Box>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <Box sx={{ width: { lg: 400, xs: "100%" } }}>
                                                    <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7007329249476972544" height="506" width="100%" frameBorder="0" allowFullScreen="" title="Embedded post"></iframe>
                                                </Box>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <Box sx={{ width: { lg: 400, xs: "100%" } }}>
                                                    <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7008325190677929984" height="506" width="100%" frameBorder="0" allowFullScreen="" title="Embedded post"></iframe>
                                                </Box>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <Box sx={{ width: { lg: 400, xs: "100%" } }}>
                                                    <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7019569152528982016" height="506" width="100%" frameBorder="0" allowFullScreen="" title="Embedded post"></iframe>
                                                </Box>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <Box sx={{ width: { lg: 400, xs: "100%" } }}>
                                                    <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7009486496638394369" height="506" width="100%" frameBorder="0" allowFullScreen="" title="Embedded post"></iframe>
                                                </Box>
                                            </SwiperSlide>
                                        </Swiper>
                                        <Stack sx={{mt: 1}}>
                                            <Chip label="view more" size="small" onClick={() => history.push("/wall")}/>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </div>
                    </Box>
                </div>
            </section>
        </>
    )
}

export default PostSection