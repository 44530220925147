import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { Icon } from "@iconify/react";

function UseCases() {
    const useCasesData = [
        {
            icon: "carbon:badge",
            title: "Badges",
            description: "",
        },
        {
            icon: "mdi:achievement-outline",
            title: "Achievements",
            description: "",
        },
        {
            icon: "mdi:badge-account-outline",
            title: "Internship Certiﬁcates",
            description: "",
        },
        {
            icon: "mdi:badge-account-horizontal-outline",
            title: "Membership Certiﬁcates",
            description: "",
        },
        {
            icon: "ic:baseline-thumb-up-off-alt",
            title: "Appreciation Certiﬁcates",
            description: "",
        },
        {
            icon: "material-symbols:back-hand-outline",
            title: "Participation Certiﬁcates",
            description: "",
        },
    ];

    return (
        <>
            <section style={{ backgroundColor: "#000" }}>
                <div className="container">
                    <Box sx={{ width: "100%" }}>
                        <Stack spacing={7}>
                            <Typography variant="h5" sx={{ textAlign: "center" }}>
                                U S E C A S E S
                            </Typography>
                            <Stack>
                                <Grid container spacing={4} justifyContent="center">
                                    {useCasesData.map((e, i) => (
                                        <Grid item lg={4} md={6} xs={10} key={i}>
                                            <Stack direction="row" spacing={2} alignItems="center">
                                                <Box sx={{ width: "70px" }}>
                                                    <Icon icon={e.icon} width="60" color="#B5EAD7" />
                                                </Box>
                                                <Stack spacing={1}>
                                                    <div style={{ color: "#fff" }}>
                                                        <h5>{e.title}</h5>
                                                    </div>
                                                    {/* <Typography variant="body1">{e.description}</Typography> */}
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Stack>
                        </Stack>
                    </Box>
                </div>
            </section>
        </>
    );
}

export default UseCases;
