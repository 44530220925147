import React from 'react';
import { Container, Link } from '@mui/material';
import "./pricing.css";

function Pricings() {
    return (
        <>
        <Container>
            <div className="pricing">
                <div className="plans">
                <h4>Our Pricing</h4>
                <div id="cards">
                    <div className="card-left">
                        <h2>Small</h2>
                        <h3 className="monthly">₹35,000<span>/annum</span></h3>
                        <hr />
                        <p>100 NFT Certificates</p>
                        <hr />
                        {/* <p>2 Users Allowed</p>
                        <hr />
                        <p>Send up to 3 GB</p>
                        <hr /> */}
                        <Link href='/schedule'><button>Know More</button></Link>
                    </div>
                    <div className="card-middle">
                        <h2>Medium</h2>
                        <h3 className="monthly">₹1,50,000<span>/annum</span></h3>
                        <hr />
                        <p>500 NFT Certificates</p>
                        <hr />
                        {/* <p>5 Users Allowed</p>
                        <hr />
                        <p>Send up to 10 GB</p>
                        <hr /> */}
                        <Link href='/schedule'><button>Know More</button></Link>
                    </div>
                    <div className="card-right">
                        <h2>Large</h2>
                        <h3 className="monthly">₹3,00,000<span>/annum</span></h3>
                        <hr />
                        <p>1500 NFT Certificates</p>
                        <hr />
                        {/* <p>10 Users Allowed</p>
                        <hr />
                        <p>Send up to 20 GB</p>
                        <hr /> */}
                        <Link href='/schedule'><button>Know More</button></Link>
                    </div>
                </div>
                </div>
            </div>
        </Container>
        </>
    )
}

export default Pricings