import {
    LIST_ALL_CREATORS,
    LIST_ALL_CREATOR_NFTS,
    LIST_NFT_DETAILS_PUBLIC,
    RESET_ALL_CREATORS,
    RESET_ALL_CREATOR_NFTS,
    RESET_NFT_DETAILS_PUBLIC,
    LIST_ALL_ORG_EVENTS,
    RESET_ALL_ORG_EVENTS,
} from "../types";

const INITIAL_STATE = {
    creatorsList: [],
    eventsList: {
        list: [],
        org_name: "",
        showViewMore: false,
        pageNumber: 1,
        pageSize: 12,
    },
    creatorNftList: {
        list: [],
        event: "",
        showViewMore: false,
        pageNumber: 1,
        pageSize: 12,
    },
    NftDetailsPublicList: {},
};

const landingReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case LIST_ALL_CREATORS:
            return {
                ...state,
                creatorsList: payload,
            };

        case RESET_ALL_CREATORS:
            return {
                ...state,
                creatorsList: INITIAL_STATE.creatorsList,
            };

        case LIST_ALL_CREATOR_NFTS:
            return {
                ...state,
                creatorNftList: {
                    pageSize: 12,
                    ...payload,
                },
            };

        case RESET_ALL_CREATOR_NFTS:
            return {
                ...state,
                creatorNftList: INITIAL_STATE.creatorNftList,
            };

        case LIST_NFT_DETAILS_PUBLIC:
            return {
                ...state,
                NftDetailsPublicList: payload,
            };

        case RESET_NFT_DETAILS_PUBLIC:
            return {
                ...state,
                NftDetailsPublicList: INITIAL_STATE.NftDetailsPublicList,
            };

        case LIST_ALL_ORG_EVENTS:
            return {
                ...state,
                eventsList: {
                    pageSize: 12,
                    ...payload,
                },
            };

        case RESET_ALL_ORG_EVENTS:
            return {
                ...state,
                eventsList: INITIAL_STATE.eventsList,
            };

        default:
            return state;
    }
};

export default landingReducer;
