import React from "react";
import { history } from "utils";
import { Button } from "@material-ui/core";
import { Slide } from "@mui/material";

const buttonStyle = {
    primary: {
        color: "#000",
        backgroundColor: "#B5EAD7",
        border: 0,
        borderRadius: "20px",
    },
    outline: {
        color: "#B5EAD7",
        border: "1px solid #B5EAD7",
        borderRadius: "20px",
    },
};

function BannerContent() {
    return (
        <>
            <section className="container-fluid banner-container">
                <div className="container">
                    <div className="row banner-row" style={{padding: "50px 0"}}>
                    <Slide direction="down" in={true} mountOnEnter unmountOnExit>
                        <div className="col-md-6 banner-txt">
                            <h1>
                                <strong>making certiﬁcates tokenised, veriﬁable & social.</strong>
                            </h1>
                            <p style={{color: "#c2c2c2", fontWeight: "100"}}>certificate issuance, <em>reimagined.</em></p>
                            <div className="btn-row row">
                                <Button
                                    className="btn btn-primary clr"
                                    style={buttonStyle.primary}
                                    onClick={() => history.push("/schedule")}
                                >
                                    <b> Schedule a meeting</b>
                                </Button>
                                <Button
                                    className="btn btn-primary clr"
                                    style={buttonStyle.outline}
                                    onClick={() => history.push("/auth/sigin")}
                                >
                                    <b> Sign In</b>
                                </Button>
                            </div>
                        </div>
                        </Slide>
                        <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                        <div className="col-md-6 banner-img">
                            <img style={{width:'65%'}} src={require("../../assets/img/landing/certificate_banner.svg").default} alt="" />
                        </div>
                        </Slide>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BannerContent;
