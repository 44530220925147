import { post } from "services";
import {
    TOGGLE_LOADING,
    LIST_HOMEPAGE_DATA,
    RESET_HOMEPAGE_DATA,
    LIST_USER_PROFILE,
    RESET_USER_PROFILE,
    SHOW_FUNQ_CREDITS,
    RESET_FUNQ_CREDITS,
    TOGGLE_CLUSTER,
    RESET_TOGGLE_CLUSTER,
    LIST_WALLET_PRIVATE_KEY,
    RESET_WALLET_PRIVATE_KEY,
} from "../types";
import {
    FETCH_HOMEPAGE_DATA,
    FETCH_USER_PROFILE,
    REDEEM_WALLET_AMOUNT,
    UPDATE_USER_PROFILE,
    FETCH_FUNQ_CREDITS,
} from "graphql";
import { toast } from "react-toastify";
import { GET_WALLET_PRIVATE_KEY } from "graphql";

export const listUserProfile = () => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        const {
            users: { cluster },
        } = getState();

        let apiResponse = await post("", {
            query: FETCH_USER_PROFILE,
            variables: { cluster: cluster },
        });

        if (apiResponse.data.customStatus) {
            let {
                view_profile: { data },
            } = apiResponse.data.data;

            dispatch({
                type: LIST_USER_PROFILE,
                payload: data,
            });
        }
        dispatch(listHomepageData());
    };
};

export const listHomepageData = () => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        const {
            users: { cluster },
        } = getState();

        let apiResponse = await post("", {
            query: FETCH_HOMEPAGE_DATA,
            variables: { cluster: cluster },
        });

        if (apiResponse.data.customStatus) {
            let {
                homepage_reports: { data },
            } = apiResponse.data.data;

            dispatch({
                type: LIST_HOMEPAGE_DATA,
                payload: data,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetHomepageData = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_HOMEPAGE_DATA });
    };
};

export const resetUserProfileData = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_USER_PROFILE });
    };
};

export const redeemWalletAmount = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: REDEEM_WALLET_AMOUNT,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetUserProfileData());
            dispatch(listUserProfile());
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const updateUserProfile = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: UPDATE_USER_PROFILE,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetUserProfileData());
            dispatch(listUserProfile());
            toast.success("Username updated");
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const showFunqCredits = () => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        const {
            users: { cluster },
        } = getState();

        let apiResponse = await post("", {
            query: FETCH_FUNQ_CREDITS,
            variables: { cluster: cluster },
        });

        if (apiResponse.data.customStatus) {
            let {
                show_funq_credits: { data },
            } = apiResponse.data.data;

            dispatch({
                type: SHOW_FUNQ_CREDITS,
                payload: data,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetFunqCredits = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_FUNQ_CREDITS });
    };
};

export const toggleCluster = (data) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_CLUSTER, payload: data });
    };
};

export const resetToggleCluster = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_TOGGLE_CLUSTER });
    };
};

export const getPrivateKey = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: GET_WALLET_PRIVATE_KEY,
            variables: requestData,
        });
        
        if (apiResponse.data.customStatus) {
            let {
                show_wallet_pr_key: { token },
            } = apiResponse.data.data;

            dispatch({
                type: LIST_WALLET_PRIVATE_KEY,
                payload: token,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetPrivateKey = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_WALLET_PRIVATE_KEY });
    };
};