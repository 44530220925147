export const FETCH_ALL_CREATORS = `query ($pageSize: Int, $pageNumber: Int ) {
  list_all_creators(page_size: $pageSize, page_number: $pageNumber ) {
    message
    data {
      _id
      name
      email
      img
      user_name
    }
    hasMore
  }
}
`;

export const FETCH_ALL_CREATOR_NFTS = `query ($username: String!, $pageSize: Int, $pageNumber: Int, $text: String, $event: String) {
  list_all_creator_nfts(user_name: $username, page_size: $pageSize, page_number: $pageNumber, text: $text, event: $event) {
    message
    data {
      creator {
        _id
        name
        email
        img
        description
      }
      event {
        _id
        name
        description
        img
      }
      arts {
        _id
        name
        description
        art_url
        nft_id
        event_id {
          _id
          name
          user_name
        }
      }
    }
    hasMore
  }
}
`;

export const FETCH_NFT_DETAILS_PUBLIC = `query ($nftId: String!) {
  list_nft_details_public(nft_id: $nftId){
    message
    data {
      _id
      name
      description
      cluster
      update_authority
      art_url
      royalty_percentage
      nft_id
      is_for_sale
      selling_price
    }
  }
}`;

export const FETCH_ALL_ORG_EVENTS = `query ($pageSize: Int, $pageNumber: Int, $orgName: String) {
  list_all_events(page_size: $pageSize, page_number: $pageNumber, org_name: $orgName) {
    message
    data {
      _id
      name
      description
      img
      user_name
    }
    hasMore
    organisation
  }
}
`;

export const SCHEDULE_DEMO_ALERT = `query ($name: String, $email: String, $mobile: String, $website: String, $need: String) {
  schedule_alert(name: $name, email: $email, mobile: $mobile, website: $website, need: $need){
    message
  }
}`
