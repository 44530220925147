import React, { useEffect, useState } from "react";
import { Box, Container, Grid } from "@mui/material";
import Loader from "components/Loader";
import NavBar from "views/landing/navBar";
import { GoogleSpreadsheet } from "google-spreadsheet";

function Wall() {
    const [datas, setDatas] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchDatas();
    }, []);

    const fetchDatas = async () => {
        setIsLoading(true);

        const SPREADSHEET_ID = process.env.REACT_APP_GL_SPREADSHEET_ID;
        const SHEET_ID = process.env.REACT_APP_GL_SHEET_ID_OF_WALL;
        const CLIENT_EMAIL = process.env.REACT_APP_GL_CLIENT_EMAIL;
        const PRIVATE_KEY = process.env.REACT_APP_GL_PRIVATE_KEY;

        const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

        try {
            await doc.useServiceAccountAuth({
                client_email: CLIENT_EMAIL,
                private_key: PRIVATE_KEY,
            });
            // loads document properties and worksheets
            await doc.loadInfo();

            const sheet = doc.sheetsById[SHEET_ID];
            const rows = await sheet.getRows();
            setDatas(rows);

            setIsLoading(false);
        } catch (e) {
            console.log("error", e);
            setIsLoading(false);
        }
    };

    return (
        <>
            <Loader visible={isLoading} />
            <NavBar />
            <Box sx={{ width: "100%", color: "#fff", pt: 13, pb: 5 }}>
                <Container>
                    <Grid container spacing={4} justifyContent="center">
                        <Grid item lg={12} xs={12}>
                            <Box sx={{ textAlign: "center", color: "#B5EAD7", fontSize: 25 }}>
                                <h4>#CertifySocial Wall</h4>
                            </Box>
                        </Grid>
                        {datas.length ? (
                            <>
                                {datas?.map((e, i) => (
                                    <Grid item key={i}>
                                        <Box
                                            sx={{
                                                width: { lg: 350, xs: "100%" },
                                                p: 0.5,
                                                borderRadius: "5px",
                                                bgcolor: "#fff",
                                            }}
                                        >
                                            <iframe
                                                src={e?.URL}
                                                height="400"
                                                width="100%"
                                                frameBorder="0"
                                                allowFullScreen=""
                                                title="Embedded post"
                                            ></iframe>
                                        </Box>
                                    </Grid>
                                ))}
                            </>
                        ) : (
                            <Grid item lg={12} xs={12}>
                                <Box
                                    sx={{
                                        height: "300px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <h6>{isLoading ? "loading..." : "no data found"}</h6>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </Container>
            </Box>
        </>
    );
}

export default Wall;
