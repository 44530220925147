import { unAuthorizedPost } from "services";
import { FETCH_ALL_CREATORS, FETCH_ALL_CREATOR_NFTS, FETCH_NFT_DETAILS_PUBLIC, FETCH_ALL_ORG_EVENTS } from "graphql";
import {
    TOGGLE_LOADING,
    RESET_ALL_CREATORS,
    LIST_ALL_CREATORS,
    RESET_ALL_CREATOR_NFTS,
    LIST_ALL_CREATOR_NFTS,
    RESET_NFT_DETAILS_PUBLIC,
    LIST_NFT_DETAILS_PUBLIC,
    LIST_ALL_ORG_EVENTS,
    RESET_ALL_ORG_EVENTS,
} from "store/types";

export const listAllCreators = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await unAuthorizedPost("", {
            query: FETCH_ALL_CREATORS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_creators: { data },
            } = apiResponse.data.data;

            dispatch({
                type: LIST_ALL_CREATORS,
                payload: data,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllCreators = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_CREATORS });
    };
};

export const listAllCreatorNfts = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        const {
            landing: {
                creatorNftList: { pageSize, pageNumber, list },
            },
        } = getState();

        let apiResponse = await unAuthorizedPost("", {
            query: FETCH_ALL_CREATOR_NFTS,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_creator_nfts: {
                    data: { arts, event },
                    hasMore,
                },
            } = apiResponse.data.data;

            let updatedList = [...list, ...arts];

            dispatch({
                type: LIST_ALL_CREATOR_NFTS,
                payload: {
                    list: updatedList,
                    event: event?.name,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllCreatorNfts = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_CREATOR_NFTS });
    };
};

export const listNftDetailsPublic = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await unAuthorizedPost("", {
            query: FETCH_NFT_DETAILS_PUBLIC,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                list_nft_details_public: { data },
            } = apiResponse.data.data;

            dispatch({
                type: LIST_NFT_DETAILS_PUBLIC,
                payload: data,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetNftDetailsPublic = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_NFT_DETAILS_PUBLIC });
    };
};

export const listAllEventsList = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        const {
            landing: {
                eventsList: { pageSize, pageNumber, list },
            },
        } = getState();

        let apiResponse = await unAuthorizedPost("", {
            query: FETCH_ALL_ORG_EVENTS,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_events: { data, organisation, hasMore },
            } = apiResponse.data.data;

            let updatedList = [...list, ...data];

            dispatch({
                type: LIST_ALL_ORG_EVENTS,
                payload: {
                    list: updatedList,
                    org_name: organisation,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllEventsList = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_ORG_EVENTS });
    };
};
