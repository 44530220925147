/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// @material-ui/core components
import { Grid } from "@material-ui/core";
import { toast } from "react-toastify";

import { sendOtp, toggleLoading, loginWithOtp } from "store/actions";
import SendOtp from "./SendOtp";
import LoginWithOtp from "./LoginWithOtp";

function Login() {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");

  useEffect(() => {
    dispatch(toggleLoading(false));
  }, []);

  const handleSendOtp = async (value) => {
    let result = await dispatch(sendOtp(value));
    if (result) {
      toast.success("OTP sent successfully");
      setEmail(value.email);
    }
  };

  const handleOtpLogin = (value) => {
    dispatch(loginWithOtp({ email: email, otp: value.otp }));
  };

  return (
    <div>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          {email === "" ? (
            <SendOtp onSubmit={handleSendOtp} />
          ) : (
            <LoginWithOtp onSubmit={handleOtpLogin} />
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default Login;
