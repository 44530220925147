const themeColors = {
    white: {
        main: "#FFFFFF",
    },
    black: {
        light: "#12263F",
        main: "#000000",
    },
    transparent: {
        main: "transparent",
    },
    gray: {
        100: "#f6f9fc",
        200: "#e9ecef",
        300: "#dee2e6",
        400: "#ced4da",
        500: "#adb5bd",
        600: "#8898aa",
        700: "#525f7f",
        800: "#32325d",
        900: "#212529",
    },
    primary: {
        main: "#B5EAD7",
        dark: "#d9a20b",
        snackbar: "#7889e8",
        badgeBg: "#eaecfb",
        badgeBgHover: "#2a44db",
        badge: "#2643e9",
    },
    secondary: {
        main: "#f7fafc",
        snackbar: "#f8fbfc",
        badgeBgHover: "#cadeeb",
        btnOutline: "#4385b1",
        btnActive: "#d2e3ee",
    },
    warning: {
        light: "#ffd600",
        main: "#fb6340",
        snackbar: "#fc7c5f",
        badgeBg: "#fee6e0",
        badgeBgHover: "#f93305",
        badge: "#ff3709",
    },
    error: {
        light: "#f3a4b5",
        main: "#f5365c",
        snackbar: "#f75676",
        badgeBg: "#fdd1da",
        badgeBgHover: "#e30b36",
        badge: "#f80031",
        dialogNotification: "#f56036",
    },
    info: {
        main: "#11cdef",
        snackbar: "#37d5f2",
        badgeBg: "#aaedf9",
        badgeBgHover: "#0c9cb7",
        badge: "#03acca",
    },
    background: {
        // default: "rgb(244, 246, 248)",
        // default:"#3b2663"
        default: "#E6E6FA",
    },
    text: {
        primary: "#525f7f",
    },
    dark: {
        tableBorder: "#1f3a68",
        tableHeadColor: "#4d7bca",
        tableHeadBgColor: "#1c345d",
        main: "#1A1B28",
        dark: "#0b1526",
        snackbar: "#3c4d69",
        badgeBg: "#4172c6",
        badgeBgHover: "#09111e",
    },
    success: {
        main: "#2dce89",
        snackbar: "#4fd69c",
        badgeBg: "#b0eed3",
        badgeBgHover: "#229c68",
        badge: "#1aae6f",
    },
};

export default themeColors;
