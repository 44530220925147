import React from "react";
import { Box, Typography, Stack } from "@mui/material";
import { Icon } from "@iconify/react";

function FirstInIndia() {

    return (
        <>
            <section style={{ backgroundColor: "#1c1c1c" }}>
                <div className="container">
                    <Box sx={{ width: "100%", p: 3 }}>
                        <Stack spacing={7}>
                            <Typography variant="h5" sx={{ textAlign: "center" }}>
                                F I R S T &nbsp; I N  &nbsp; I N D I A
                            </Typography>

                            <Stack direction={{lg: "row", md: "row", sm: "row", xs: "column-reverse"}} alignItems="center" spacing={8} sx={{color: "#fff"}}>
                                <Stack spacing={5}>
                                <h5>We are the First in India that helps issue certificates as NFTs that is compatible with Meta platforms like Instagram</h5>
                                <h5><a style={{color: "#B5EAD7", cursor: "pointer"}} href="https://www.instagram.com/p/ClGj_XnpuCx/?igshid=MDJmNzVkMjY=" target="_blank" rel="noreferrer" >
                                <span style={{textDecoration: "underline"}}>Here is the first ever certificate</span> </a> 
                                to be issued and posted in a Meta platform as a digital collectible</h5>
                                </Stack>
                                <Stack>
                                    <Icon icon="twemoji:flag-india" width="200"/>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Box>
                </div>
            </section>
        </>

    );
}

export default FirstInIndia;
