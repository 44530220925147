import { LIST_HOMEPAGE_DATA, LIST_USER_PROFILE, RESET_HOMEPAGE_DATA, RESET_USER_PROFILE, SHOW_FUNQ_CREDITS, RESET_FUNQ_CREDITS, TOGGLE_CLUSTER, RESET_TOGGLE_CLUSTER, LIST_WALLET_PRIVATE_KEY, RESET_WALLET_PRIVATE_KEY } from "../types";

const INITIAL_STATE = {
    profileData: {},
    homepageData: {},
    funqCredits: {},
    cluster: 'mainnet-beta',
    privateKeyToken: ""
};

const userReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case LIST_HOMEPAGE_DATA:
            return {
                ...state,
                homepageData: payload,
            };

        case LIST_USER_PROFILE:
            return {
                ...state,
                profileData: payload,
            };

        case SHOW_FUNQ_CREDITS:
            return {
                ...state,
                funqCredits: payload,
            };

        case TOGGLE_CLUSTER:
            return {
                ...state,
                cluster: payload,
            };

        case LIST_WALLET_PRIVATE_KEY:
            return {
                ...state,
                privateKeyToken: payload,
            };

        case RESET_WALLET_PRIVATE_KEY:
            return {
                ...state,
                privateKeyToken: INITIAL_STATE.privateKeyToken,
            };

        case RESET_TOGGLE_CLUSTER:
            return {
                ...state,
                cluster: INITIAL_STATE.cluster,
            };

        case RESET_USER_PROFILE:
            return {
                ...state,
                profileData: INITIAL_STATE.profileData,
            };

        case RESET_HOMEPAGE_DATA:
            return {
                ...state,
                homepageData: INITIAL_STATE.homepageData,
            };

        case RESET_FUNQ_CREDITS:
            return {
                ...state,
                funqCredits: INITIAL_STATE.funqCredits,
            };

        default:
            return state;
    }
};

export default userReducer;
