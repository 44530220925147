/*eslint-disable*/
import React, { useState } from "react";
import { GoogleSpreadsheet } from "google-spreadsheet";

import { Box, Button, Stack, OutlinedInput } from "@mui/material";
import { CircularProgress } from "@material-ui/core";
// import screenShot from "./../../assets/img/landing/certify-screenshot-2.png";
import CustomToast from "components/Custom/Toast";

export default function RegisterEmail() {
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [successToast, setSuccessToast] = useState(false);
    const [errToast, setErrToast] = useState(false);

    const handleSubmit = async () => {
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            return setErrToast(true);
        }
        setIsLoading(true);

        const SPREADSHEET_ID = process.env.REACT_APP_GL_SPREADSHEET_ID;
        const SHEET_ID = process.env.REACT_APP_GL_SHEET_ID.toString();
        const CLIENT_EMAIL = process.env.REACT_APP_GL_CLIENT_EMAIL;
        const PRIVATE_KEY = process.env.REACT_APP_GL_PRIVATE_KEY;

        const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

        try {
            await doc.useServiceAccountAuth({
                client_email: CLIENT_EMAIL,
                private_key: PRIVATE_KEY,
            });
            // loads document properties and worksheets
            await doc.loadInfo();

            const sheet = doc.sheetsById[SHEET_ID];
            const rows = await sheet.getRows();

            const filtered = rows.filter((e) => e.Email === email);

            if (!filtered.length) {
                await sheet.addRow({ Email: email });
            }

            setIsLoading(false);
            return setSuccessToast(true);
            // return window.alert("Email registered");
        } catch (e) {
            setIsLoading(false);
            console.log("error", e);
            return;
            // console.error("Error: ", e);
        }
    };
    return (
        <>
            <section
                style={{
                    backgroundColor: "#000",
                    color: "#fff",
                    borderTop: "1px solid #333",
                    borderBottom: "1px solid #333",
                }}
            >
                <div className="container">
                    <Box sx={{ width: "100%", p: 3 }}>
                        <Stack spacing={7}>
                            {/* <Typography variant="h5" sx={{ textAlign: "center" }}>
                                Reach out to us to launch your NFT certificates
                            </Typography> */}

                            <Stack direction={{ lg: "row", md: "row", xs: "column" }} spacing={10} alignItems="center">
                                <Stack>
                                    <Box>
                                        <img src='https://certify-social.s3.ap-south-1.amazonaws.com/insta-collectible.gif' alt="" width={600} style={{ borderRadius: "5px", height: "550px" }} />
                                    </Box>
                                </Stack>
                                <Stack spacing={8}>
                                    <Stack>
                                        <Box>
                                            <h3>
                                                <b>
                                                    The certificate is not just a scanned image but a verifiable
                                                    <span style={{ color: "#B5EAD7" }}>
                                                        <a
                                                            href="https://about.fb.com/news/2022/05/introducing-digital-collectibles-to-showcase-nfts-instagram/"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {" "}
                                                            digital collectible{" "}
                                                        </a>
                                                    </span>
                                                    which was issued as an NFT
                                                </b>
                                            </h3>
                                        </Box>
                                    </Stack>
                                    <Stack>
                                        <Box>
                                            <h4>
                                                powered by
                                                <span style={{ color: "#B5EAD7" }}> certify.social </span>
                                            </h4>
                                        </Box>
                                    </Stack>
                                    <Stack direction="row" spacing={2}>
                                        <OutlinedInput
                                            id="outlined-basic"
                                            // label="Enter Email ID"
                                            placeholder="Enter Email ID"
                                            variant="outlined"
                                            type={"email"}
                                            value={email}
                                            style={{ backgroundColor: "#fff", fontWeight: "bold" }}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <Button
                                            style={{ backgroundColor: "#B5EAD7", color: "#000", width: "130px" }}
                                            variant="contained"
                                            disabled={email === ""}
                                            onClick={handleSubmit}
                                        >
                                            {isLoading ? <CircularProgress color="inherit" /> : <b>try now</b>}
                                        </Button>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Box>
                </div>
            </section>
            <CustomToast open={successToast} setOpen={setSuccessToast} message="Email registered" type="success" />
            <CustomToast open={errToast} setOpen={setErrToast} message="Invalid email address" type="error" />
        </>
    );
}
