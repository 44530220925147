import React from 'react';
import { Box, Button, Stack, Typography } from "@mui/material";
import { Chip } from '@material-ui/core';

function ConnectToInstagram({ setTab }) {
    return (
        <Box sx={{ color: "#B5EAD7" }}>
            <Stack spacing={5}>
                <Stack sx={{ minHeight: "300px" }} spacing={2} alignItems="center">
                    <h4 style={{fontWeight:"500", textAlign: "center"}}>connect the phantom wallet with your instagram account </h4>
                    <Box sx={{ width: "100%" }}>
                        <Stack alignItems="center" spacing={1}>
                            <Chip
                                color="primary"
                                size="small"
                                label="Click to watch video"
                                onClick={() => window.open("https://youtu.be/aECDAl3Iz10")}
                            />
                            <Box sx={{ width: {lg: "50%", md: "50%", sm: "70%", xs: "100%"}, height: {lg: 300, xs: 200} }}>
                                <iframe
                                    src="https://www.youtube.com/embed/aECDAl3Iz10"
                                    title="video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    style={{
                                        border: 0,
                                        borderRadius: "10px",
                                        width: "100%",
                                        height: "100%",
                                    }}
                                />
                            </Box>
                        </Stack>
                    </Box>
                </Stack>
                <Stack direction={{ lg: "row", xs: "column" }} spacing={2} alignItems="center" justifyContent="center">
                    <Typography textAlign="center" color="#B5EAD7">
                        <b>Note:</b> if you are yet to connect certify.social wallet with phantom wallet, {" "}
                        <span target="_blank" rel="noreferrer" style={{ textDecoration: "underline" }}>
                            <a href="https://youtu.be/NSZJ-8lnHdQ" target="_blank" rel="noreferrer">Click to watch video</a>
                        </span>
                    </Typography>
                </Stack>
                <Stack alignItems="flex-start">
                    <Button
                        variant="contained"
                        style={{ backgroundColor: "#5c5c5c", color: "#B5EAD7" }}
                        onClick={() => setTab(1)}
                    >
                        <b>Back</b>
                    </Button>
                </Stack>
            </Stack>
        </Box>
    );
}
export default ConnectToInstagram