export const csvToJson = (csvData, fieldsObj) => {
    const keyArray = csvData[0];
    const valuesArray = csvData.splice(1);
    let jsonData = {};
    let jsonArray = [];
    valuesArray.forEach((valueArr) => {
        if (valueArr[0] !== "") {
            valueArr.forEach((value, index) => {
                jsonData[keyArray[index]] = value;
            });
            jsonArray.push(jsonData);
            jsonData = {};
        }
    });

    jsonArray.map((e, i) => {
        Object.keys(e).map((j, k) => {
            let newKey = fieldsObj[j] || j;
            delete Object.assign(e, { [newKey]: e[j] })[j];
            e.id = i;
            return i;
        });
        return e;
    });
    return jsonArray;
};
