export const LOGIN_WITH_OTP = `query ($email: String!, $otp: String!) {
    login_with_otp(email: $email, otp: $otp) {
      message
      token
    }
  }
  `;

export const SEND_OTP = `mutation ($email: String!, $isForPrKey: Boolean) {
    send_otp(email: $email, is_for_pr_key: $isForPrKey) {
      message
    }
  }
  `;

export const SIGNUP_CREATOR = `mutation($name: String!, $email: String!, $mobile:String){
  sign_up_creator(name: $name, email: $email, mobile: $mobile){
    message
  }
}`

export const VERIFY_CREATOR = `mutation($email: String!, $otp: String!){
  verify_creator(email: $email, otp: $otp){
    message
  }
}`