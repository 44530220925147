import React from "react";
import { Grid, Card, CardContent } from "@material-ui/core";
import CustomTextButton from "../Custom/Buttons/TextButton";
import { history } from "utils";

const NftCard = ({
    data = [],
    showStatus = true,
    emptyText = "No data available",
    showViewMoreButton = false,
    viewMoreButtonClick,
}) => {
    return (
        <div className="content" style={{ padding: "1em" }}>
            <Grid container spacing={3}>
                {data.length ? (
                    <>
                        {data.map((e, i) => {
                            return (
                                <Grid item xl={3} lg={3} md={4} sm={6} xs={12} key={i}>
                                    <Card
                                        style={{
                                            // borderRadius: "10px",
                                            textAlign: "center",
                                            height: "100%",
                                            width: "100%",
                                        }}
                                    >
                                        <CardContent style={{paddingBottom: 0}}>
                                            <h5 style={{ display: "inline-block", width: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", textAlign: "center" }}>{e?.name}</h5>
                                            <Grid container justifyContent="center">
                                                <img src={e?.art_url} alt="" />
                                            </Grid>
                                            <br />
                                            {showStatus?
                                            <h6>Status: {e?.status}</h6>:null}
                                            <CustomTextButton
                                                title="open"
                                                otherProps={{ size: "sm" }}
                                                onClick={() => history.push(`/app/nft/${e?._id}`)}
                                            />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            );
                        })}
                        {/* <div style={{ display: "flex", justifyContent: "center", padding: "1em" }}> */}
                        <Grid container justifyContent="center">
                            {showViewMoreButton ? (
                                <CustomTextButton
                                    color="secondary"
                                    variant="outlined"
                                    title="View More"
                                    onClick={viewMoreButtonClick}
                                    size="small"
                                />
                            ) : null}
                        </Grid>
                        {/* </div> */}
                    </>
                ) : (
                        <Grid container>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Card>
                                    <CardContent>
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <h4 style={{ fontSize: "15px", fontWeight: "bold", fontFamily: "inherit" }}>
                                                {emptyText}
                                            </h4>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                )}
            </Grid>
        </div>
    );
};

export default NftCard;
