import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Chip } from "@material-ui/core";

function SetupPhantomWallet({ setTab }) {
  return (
    <Box sx={{ color: "#B5EAD7" }}>
      <Stack spacing={5}>
        <Stack
          spacing={2}
          alignItems="center"
          textAlign="center"
        >
          <h4 style={{fontWeight:"500"}}>install phantom wallet app</h4>
          <Chip
            color="primary"
            size="small"
            label="https://phantom.app/download"
            onClick={() => window.open("https://phantom.app/download")}
          />
        </Stack>
        <Stack
          spacing={2}
          alignItems="center"
        >
          <Typography color="#fff">Phantom for Mobile</Typography>
          <Stack direction="row" spacing={2}>
            <a
              href="https://apps.apple.com/app/phantom-solana-wallet/1598432977"
              target="_blank"
              rel="noreferrer"
            >
              <img src="https://i.ibb.co/ctrx5cZ/image.png" width={200} alt="" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=app.phantom&pli=1"
              target="_blank"
              rel="noreferrer"
            >
              <img src="https://i.ibb.co/zhw4r4G/image.png" width={200} alt="" />
            </a>
          </Stack>
        </Stack>
        <Stack
          spacing={2}
          alignItems="center"
        >
          <Typography color="#fff">Phantom for Desktop</Typography>
          <Stack direction="row" spacing={2}>
          <a href="https://chrome.google.com/webstore/detail/phantom/bfnaelmomeimhlpmgjnjophhpkkoljpa" target="_blank" rel="noreferrer">
            <img src="https://i.ibb.co/1Lkzwy0/image.png" width={90} alt="" />
          </a>
          <a href="https://chrome.google.com/webstore/detail/phantom/bfnaelmomeimhlpmgjnjophhpkkoljpa" target="_blank" rel="noreferrer">
            <img src="https://i.ibb.co/McNstRh/brave-browser-logo-icon-153013.png" width={90} alt="" />
          </a>
          <a href="https://addons.mozilla.org/en-US/firefox/addon/phantom-app/" target="_blank" rel="noreferrer">
            <img src="https://i.ibb.co/bbzhp9h/firefox-browser-logo-icon-152991.png" width={90} alt="" />
          </a>
          <a href="https://chrome.google.com/webstore/detail/phantom/bfnaelmomeimhlpmgjnjophhpkkoljpa" target="_blank" rel="noreferrer">
            <img src="https://i.ibb.co/jv1TpwF/edge-browser-logo-icon-152998.png" width={90} alt="" />
          </a>
          </Stack>
        </Stack>
        <Stack
          spacing={2}
          alignItems="center"
          textAlign="center"
        >
          <h4 style={{fontWeight:"500"}}>
          now connect certify.social wallet with phantom wallet
          </h4>
          <Chip
            color="primary"
            size="small"
            label="Click to watch video"
            onClick={() => window.open("https://youtu.be/NSZJ-8lnHdQ")}
          />
          <Box sx={{ width: { lg: "50%", md: "50%", sm: "70%", xs: "100%" }, height: { lg: 300, xs: 200 } }}>
            <iframe
              src="https://www.youtube.com/embed/NSZJ-8lnHdQ"
              title="video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{
                border: 0,
                borderRadius: "10px",
                width: "100%",
                height: "100%",
              }}
            />
          </Box>
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Button
            variant="contained"
            style={{ backgroundColor: "#5c5c5c", color: "#B5EAD7" }}
            onClick={() => setTab(0)}
          >
            <b>Back</b>
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: "#ccc", color: "#000" }}
            onClick={() => setTab(2)}
          >
            <b>Next</b>
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}

export default SetupPhantomWallet;
