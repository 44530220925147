import React from "react";
import "../landing/style.css";

import NavBar from "../landing/navBar";
import Footer from "../landing/footer";

function AboutUs() {
    return (
        <div>
            <NavBar />
            <section className="container-fluid banner-container">
                <div className="container">
                    <div className="row banner-row">
                        <div className="col-md-6 banner-txt">
                            <h1>
                                <strong style={{ color: "#B5EAD7" }}>certify.social</strong>
                            </h1>
                            <p>
                                A platform which enables employees, students, customers, partners to receive certiﬁcates,
                                badges and documents from their organisation as NFTs on a Blockchain
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default AboutUs;
