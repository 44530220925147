import React from "react";
import { Button } from "reactstrap";

const CustomTextButton = ({
    color = "secondary",
    onClick,
    disabled = false,
    style = {},
    title = "Click here",
    otherProps = {},
}) => {
    return (
        <Button color={color} onClick={onClick} style={style} {...otherProps} disabled={disabled}>
            {title}
        </Button>
    );
};

export default CustomTextButton;
