import React from "react";

import { Modal, ModalBody, ModalHeader } from "reactstrap";

const CustomModal = ({ title = "", content, visible, handleClose }) => {
    return (
        <Modal centered isOpen={visible} backdrop toggle={handleClose}>
            <ModalHeader style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ fontSize: 20, textTransform: "uppercase" }}>{title}</div>
            </ModalHeader>
            <ModalBody>{content}</ModalBody>
        </Modal>
    );
};

export default CustomModal;
