import { post } from "services";
import { TOGGLE_LOADING, LIST_ALL_TXNS, RESET_ALL_TXNS, TOGGLE_TXNS_VIEW_MODE } from "../types";
import { FETCH_ALL_TXNS } from "graphql";

export const listAllTxns = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        const { pageSize, pageNumber, list } = getState().txns;

        let apiResponse = await post("", {
            query: FETCH_ALL_TXNS,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_transactions: { data, hasMore },
            } = apiResponse.data.data;

            let updatedList = [...list, ...data];

            dispatch({
                type: LIST_ALL_TXNS,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetTxnsData = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_TXNS });
    };
};

export const toggleViewMode = data => {
    return async dispatch => {
      dispatch({ type: TOGGLE_TXNS_VIEW_MODE, payload: data });
    };
  };