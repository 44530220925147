import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { Icon } from "@iconify/react";

function HowItWorks() {
    const howItWorksData = [
        {
            icon: "material-symbols:looks-one-outline",
            title: "Set up certify.social Business Account",
            description: "Onboard the organisation and verify the authenticity of the issuer",
        },
        {
            icon: "material-symbols:looks-two-outline",
            title: "Start generating certiﬁcates",
            description:
                "Flexible to upload as an image / pdf or use our existing templates. Generate certiﬁcates with a single click",
        },
        {
            icon: "mdi:numeric-three-box-outline",
            title: "Issuing certiﬁcates",
            description: "Start issuing certiﬁcates or documents and share these as links with users via email / chat",
        },
        {
            icon: "mdi:numeric-four-box-outline",
            title: "User can access certiﬁcates",
            description:
                "One can access the certiﬁcate and share it on social media or with an employer as a veriﬁable,authentic document",
        },
    ];

    return (
        <>
            <section style={{ backgroundColor: "#000" }}>
                <div className="container">
                    <Box sx={{ width: "100%" }}>
                        <Stack spacing={7}>
                            <Typography variant="h5" sx={{ textAlign: "center" }}>
                                H O W &nbsp; I T &nbsp; W O R K S
                            </Typography>
                            <Stack>
                                <Grid container spacing={4} justifyContent="center">
                                    {howItWorksData.map((e, i) => (
                                        <Grid item lg={4} md={6} xs={10} key={i}>
                                            <Stack direction="row" spacing={2} alignItems="center">
                                                <Box sx={{ width: "70px" }}>
                                                    <Icon icon={e.icon} width="60" color="#B5EAD7" />
                                                </Box>
                                                <Stack spacing={1}>
                                                    <div style={{ color: "#fff" }}>
                                                        <h5>{e.title}</h5>
                                                    </div>
                                                    <Typography variant="body1">{e.description}</Typography>
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Stack>
                        </Stack>
                    </Box>
                </div>
            </section>
        </>
    );
}

export default HowItWorks;
