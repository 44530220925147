import React, { useState } from "react";
import {
    Box,
    Card,
    Container,
    FormControl,
    Grid,
    InputAdornment,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    TextField,
    Button,
    CircularProgress,
    Slide,
} from "@mui/material";
import { Email, Person, Phone, Public } from "@material-ui/icons";
import { countryISDCodes } from "variables/isdCodeList";
import { Formik } from "formik";
import * as Yup from "yup";
import { GoogleSpreadsheet } from "google-spreadsheet";
import CustomToast from "components/Custom/Toast";
import NavBar from "views/landing/navBar";
import { unAuthorizedCommonApiCall } from "store/actions";
import { SCHEDULE_DEMO_ALERT } from "graphql";

const errMsgStyle = {
    display: "block",
    color: "red",
    fontSize: 13,
}

export default function ScheduleDemoPage() {
    const [isd, setIsd] = useState("91");
    const [isLoading, setIsLoading] = useState(false);
    const [successToast, setSuccessToast] = useState(false);
    const [errToast, setErrToast] = useState(false);

    const initialValues = {
        name: "",
        email: "",
        phone: "",
        website: "",
        note: "",
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().max(255).required("Name is required"),
        email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
        phone: Yup.string().max(255).required("Phone number is required"),
    })

    const handleSubmit = async (values) => {
        setIsLoading(true);
        values.isdCode = `+${isd}`;

        const SPREADSHEET_ID = process.env.REACT_APP_GL_SPREADSHEET_ID;
        const SHEET_ID = process.env.REACT_APP_GL_SHEET_ID.toString();
        const CLIENT_EMAIL = process.env.REACT_APP_GL_CLIENT_EMAIL;
        const PRIVATE_KEY = process.env.REACT_APP_GL_PRIVATE_KEY;

        const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

        try {
            await doc.useServiceAccountAuth({
                client_email: CLIENT_EMAIL,
                private_key: PRIVATE_KEY,
            });
            // loads document properties and worksheets
            await doc.loadInfo();

            const sheet = doc.sheetsById[SHEET_ID];
            const rows = await sheet.getRows();

            const filtered = rows.filter((e) => e.Email === values.email);

            if (!filtered.length) {
                let date = new Date()
                await unAuthorizedCommonApiCall(
                    SCHEDULE_DEMO_ALERT, 
                    {
                        mobile : values.phone,
                        need: values.note,
                        ...values
                    }
                )
                await sheet.addRow({
                    Date: date.toLocaleString(),
                    Email: values.email,
                    Name: values.name,
                    ISD: values.isdCode,
                    Phone: values.phone,
                    Website: values.website,
                    Note: values.note,
                });
            }

            setIsLoading(false);
            return setSuccessToast(true);
        } catch (e) {
            console.log("error", e);
            setIsLoading(false);
            return setErrToast(true);
        }
    }

    const handleIsdChange = (e) => {
        setIsd(e.target.value);
    };

    // const alertScheduleApiCall = (data) => {
    //     unAuthorizedCommonApiCall(SCHEDULE_DEMO_ALERT, data)
    // }

    return (
        <>
            <NavBar />
            <Box sx={{ width: "100%", color: "#fff" }}>
                <Container>
                    <Stack
                        alignItems="center"
                        justifyContent={{ lg: "center", md: "center", sm: "center", sx: "flex-start" }}
                        sx={{ minHeight: "100vh", p: "40px 10px", pt: "80px" }}
                    >
                        <Grid
                            container
                            spacing={5}
                            alignItems="center"
                            justifyContent={{ lg: "flex-start", md: "center", sm: "center", sx: "center" }}
                        >
                            <Grid item lg={6} md={6} sm={8} xs={12}>
                                <Slide direction="down" in={true} mountOnEnter unmountOnExit>
                                    <Stack spacing={3}>
                                        <h1 style={{ color: "#B5EAD7" }}>
                                            <b>Reach out to us to launch your NFT certificates</b>
                                        </h1>
                                        <h5 style={{ color: "#ccc", paddingRight: "4rem" }}>
                                            Get a demo where we will help you understand how you can wow your team.
                                        </h5>
                                    </Stack>
                                </Slide>
                            </Grid>
                            <Grid item lg={5} md={5} sm={8} xs={12}>
                                <Stack>
                                    <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                                        <Card>
                                            <Formik
                                                initialValues={initialValues}
                                                validationSchema={validationSchema}
                                                onSubmit={handleSubmit}
                                            >
                                                {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                                                    <form onSubmit={handleSubmit}>
                                                        <Stack sx={{ p: 4 }} spacing={2}>
                                                            <Stack>
                                                                <OutlinedInput
                                                                    fullWidth
                                                                    size="small"
                                                                    autoComplete="on"
                                                                    type="text"
                                                                    name="name"
                                                                    placeholder="Your name"
                                                                    defaultValue={values.name}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    required={true}
                                                                    startAdornment={
                                                                        <InputAdornment position="start">
                                                                            <Person />
                                                                        </InputAdornment>
                                                                    }
                                                                />
                                                                {Boolean(touched.name && errors.name) ? (
                                                                    <div style={errMsgStyle}>
                                                                        {errors.name}
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </Stack>
                                                            <Stack>
                                                                <OutlinedInput
                                                                    fullWidth
                                                                    size="small"
                                                                    autoComplete="on"
                                                                    type="email"
                                                                    name="email"
                                                                    placeholder="Email"
                                                                    defaultValue={values.email}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    required={true}
                                                                    startAdornment={
                                                                        <InputAdornment position="start">
                                                                            <Email />
                                                                        </InputAdornment>
                                                                    }
                                                                />
                                                                {Boolean(touched.email && errors.email) ? (
                                                                    <div style={errMsgStyle}>
                                                                        {errors.email}
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </Stack>
                                                            <Stack direction="row">
                                                                <FormControl sx={{ width: "30%" }} size="small">
                                                                    <Select value={isd} onChange={handleIsdChange}>
                                                                        {countryISDCodes.map((countries, index) => (
                                                                            <MenuItem value={countries?.code} key={index}>
                                                                                {countries?.iso}- {countries?.country}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                                <Stack>
                                                                    <OutlinedInput
                                                                        fullWidth
                                                                        size="small"
                                                                        autoComplete="on"
                                                                        type="number"
                                                                        name="phone"
                                                                        placeholder="Phone number"
                                                                        defaultValue={values.phone}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                        required={true}
                                                                        startAdornment={
                                                                            <InputAdornment position="start">
                                                                                <Phone /> +{isd}
                                                                            </InputAdornment>
                                                                        }
                                                                    />
                                                                    {Boolean(touched.phone && errors.phone) ? (
                                                                        <div style={errMsgStyle}>
                                                                            {errors.phone}
                                                                        </div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </Stack>
                                                            </Stack>
                                                            <OutlinedInput
                                                                fullWidth
                                                                size="small"
                                                                type="text"
                                                                name="website"
                                                                placeholder="Your website"
                                                                defaultValue={values.website}
                                                                onChange={handleChange}
                                                                startAdornment={
                                                                    <InputAdornment position="start">
                                                                        <Public />
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                            <TextField
                                                                fullWidth
                                                                type="text"
                                                                name="note"
                                                                placeholder="What is your need?"
                                                                defaultValue={values.note}
                                                                onChange={handleChange}
                                                            />
                                                            <Button
                                                                type="submit"
                                                                variant="contained"
                                                                style={{ backgroundColor: "#B5EAD7", color: "#000" }}
                                                            >
                                                                {isLoading ?
                                                                    <CircularProgress size={23} color="inherit" /> :
                                                                    <b>Schedule meeting</b>
                                                                }
                                                            </Button>
                                                        </Stack>
                                                    </form>
                                                )}
                                            </Formik>
                                        </Card>
                                    </Slide>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Stack>
                </Container>
            </Box>
            <CustomToast open={successToast} setOpen={setSuccessToast} message="Schedule success! We will reach out you soon" type="success" />
            <CustomToast open={errToast} setOpen={setErrToast} message="Failed to schedule" type="error" />
        </>
    );
}
