export const FETCH_USER_PROFILE = `query ($cluster: String) {
  view_profile(cluster: $cluster) {
    message
    data {
      _id
      name
      email
      img
      is_creator
      acc_balance
      funq_credits
      wallet_address
      user_name
    }
  }
}
`;

export const UPDATE_USER_PROFILE = `mutation($name: String!){
  update_profile(name: $name){
    message
  }
}`;

export const FETCH_HOMEPAGE_DATA = `query ($cluster: String) {
  homepage_reports(cluster: $cluster){
    message
    data {
      total_own_nfts
      total_sold_nfts
    }
  }
}`;

export const REDEEM_WALLET_AMOUNT = `mutation ($destWalletId: String!, $amount: Float!) {
  redeem_wallet_amount(dest_wallet_id: $destWalletId, amount: $amount) {
    message
  }
}
`;

export const FETCH_FUNQ_CREDITS = `query ($cluster: String) {
  show_funq_credits(cluster: $cluster){
    message
    data{
      credits
      is_creator
      is_org
    }
  }
}`;

export const GET_WALLET_PRIVATE_KEY = `query ($otp: String!) {
  show_wallet_pr_key (otp: $otp){
    message
    token
  }
}`;