import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { store, persistor } from "store/store";
import Main from "./Main";
import themeColors from "assets/theme/colors";

import 'swiper/swiper-bundle.css';

const App = () => {
    const lightTheme = createTheme({
        palette: {
          type: "light",
          primary: {
            light: "#f79e20",
            main: "#B5EAD7",
            dark: "#004d40"
          }
        },
        overrides: {
          MuiTabs: {
            indicator: {
              // backgroundColor: themeColors.primary.main + "!important",
              border: "1px solid" + themeColors.primary.main,
            },
            scroller: {
              // padding: ".5rem",
            },
          },
          MuiTab: {
            root: {
              fontSize: "small",
              fontWeight: "500",
              // padding: ".75rem 1rem",
              // transition: "all .15s ease",
              // boxShadow: "0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)",
              // borderRadius: ".375rem",
              // display: "block",
              // textAlign: "center",
              // flex: "1 1 auto",
              // maxWidth: "unset",
              "&$selected": {
                color: themeColors.primary.main + "!important",
                // backgroundColor: themeColors.primary.main + "!important",
              },
              "&:not(:last-child)": {
                marginRight: "1rem",
              },
              "& svg": {
                width: "1rem!important",
                height: "1rem!important",
                position: "relative",
                top: "2px",
              },
            },
            wrapper: {
              color: themeColors.white.main,
              flexDirection: "row",
            },
            labelIcon: {
              minHeight: "unset",
              paddingTop: ".75rem",
            },
          },
          MuiTabPanel: {
            root: {
              padding: "0",
            },
          },
        }
      });
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={lightTheme}>
                <Main />
                </ThemeProvider>
            </PersistGate>
        </Provider>
    );
};

export default App;
