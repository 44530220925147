export const getBase64 = async (file) => {
    if (file.length) {
        var reader = new FileReader();
        reader.readAsDataURL(file[0]);

        return new Promise((reslove, reject) => {
            reader.onload = () => reslove(reader.result);
            reader.onerror = (error) => reject(error);
        });
    } else {
        return "";
    }
};
