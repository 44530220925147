/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Card } from "reactstrap";
import { FormControl, Grid, MenuItem, Select, Switch } from "@material-ui/core";

import CustomTable from "components/Custom/Tables/CustomTable";
// import CustomIconButton from "components/Custom/Buttons/IconButton";
import CustomModal from "components/Custom/Modal";
import CreateNewNft from "./CreateNewNft";

import { listAllNfts, resetNftsData, showFunqCredits, resetFunqCredits, toggleViewMode } from "store/actions";
import CustomTextButton from "components/Custom/Buttons/TextButton";
import { history } from "utils";
import { toast } from "react-toastify";
import NftCard from "components/Cards/NftCard";
import { Box, Stack } from "@mui/material";

const NftList = () => {
    const dispatch = useDispatch();
    const [status, setStatus] = useState("");
    const [addNewModal, setAddNewModal] = useState(false);
    // const [infoModal, setInfoModal] = useState(false);

    const { nftsData, showViewMore, funqCreditsData, cluster } = useSelector(
        ({ nfts: { list, showViewMore }, users: { funqCredits, cluster } }) => ({
            nftsData: list,
            showViewMore,
            funqCreditsData: funqCredits,
            cluster,
        })
    );
    const { viewMode } = useSelector(({ txns: { isCardView } }) => ({
        viewMode: isCardView,
    }));

    useEffect(() => {
        dispatch(resetNftsData());
        fetchMoreNfts();
        dispatch(showFunqCredits());
        dispatch(resetFunqCredits());
    }, [status]);

    useEffect(() => {
        dispatch(resetNftsData());
        fetchMoreNfts();
        dispatch(showFunqCredits());
        dispatch(resetFunqCredits());
    }, [cluster]);

    const fetchMoreNfts = () => {
        dispatch(listAllNfts({ status: status }));
    };

    const handleAddNewModalClose = () => {
        setAddNewModal(false);
    };

    // const handleInfoModalClose = () => {
    //     setInfoModal(!infoModal);
    // };

    // const handleAddnewButton = () => {
    //     if (!funqCreditsData?.is_creator) {
    //         toast.error("There is no access to create certificates for you");
    //     } else if (funqCreditsData?.credits === 0) {
    //         toast.error("You have no credits");
    //     } else {
    //         setAddNewModal(true);
    //     }
    // };

    // const handleBulkUploadButton = () => {
    //     if (!funqCreditsData?.is_creator) {
    //         toast.error("There is no access to create certificates for you");
    //     } else if (funqCreditsData?.credits === 0) {
    //         toast.error("You have no credits");
    //     } else {
    //         history.push("/app/upload");
    //     }
    // };

    const handleChange = (event) => {
        dispatch(toggleViewMode(event.target.checked));
    };

    return (
        <>
            <Container fluid>
                <Grid container>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Card>
                            <Box sx={{ p: 1, width: "100%" }}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={8}>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            {/* <Stack>
                                                <CustomIconButton
                                                    id="add-new-btn"
                                                    title="Add New"
                                                    icon="fas fa-plus"
                                                    onClick={handleAddnewButton}
                                                />
                                            </Stack>
                                            <Stack>
                                                <CustomIconButton
                                                    id="upload-btn"
                                                    title="Bulk Upload"
                                                    icon="fas fa-upload"
                                                    onClick={handleBulkUploadButton}
                                                />
                                            </Stack> */}
                                            <Box
                                                sx={{
                                                    backgroundColor: "#262734",
                                                    color: "#fff",
                                                    pl: "10px",
                                                    borderRadius: "5px",
                                                }}
                                            >
                                                <Stack direction="row" alignItems="center">
                                                    <b>Cardview</b>
                                                    <Switch
                                                        checked={viewMode}
                                                        onChange={handleChange}
                                                        inputProps={{ "aria-label": "controlled" }}
                                                        color="primary"
                                                    />
                                                </Stack>
                                            </Box>
                                        </Stack>
                                    </Grid>
                                    {/* <Grid item xl={6} lg={6} md={6} sm={6} xs={4}>
                                        <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                style={{
                                                    maxWidth: "200px",
                                                    minWidth: "120px",
                                                    paddingLeft: "10px",
                                                    paddingRight: "5px",
                                                }}
                                            >
                                                <Select
                                                    value={status}
                                                    onChange={(event) => {
                                                        setStatus(event.target.value);
                                                    }}
                                                    displayEmpty
                                                >
                                                    <MenuItem value="">
                                                        <em>All</em>
                                                    </MenuItem>
                                                    <MenuItem value="pending">Pending</MenuItem>
                                                    <MenuItem value="created">Created</MenuItem>
                                                    <MenuItem value="sold">Sold</MenuItem>
                                                    <MenuItem value="sales">Listed</MenuItem>
                                                    <MenuItem value="transferred">Transferred</MenuItem>
                                                    <MenuItem value="received">Received</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid> */}
                                </Grid>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <Container fluid>
                <Grid container>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        {!viewMode ? (
                            <CustomTable
                                title="Certificates"
                                columns={[
                                    {
                                        title: "Actions",
                                        render: (rowData) => (
                                            <CustomTextButton
                                                title="open"
                                                otherProps={{ size: "sm" }}
                                                onClick={() => history.push(`/app/nft/${rowData._id}`)}
                                            />
                                        ),
                                    },
                                    { title: "Name", field: "name" },
                                    { title: "Description", field: "description" },
                                    { title: "NFT ID", field: "nft_id" },
                                    {
                                        title: "Certificate",
                                        field: "art_url",
                                        render: (rowData) => <img src={rowData?.art_url} width={40} height={40} />,
                                    },
                                    { title: "Status", field: "status" },
                                ]}
                                data={nftsData}
                                showViewMoreButton={showViewMore}
                                viewMoreButtonClick={() => fetchMoreNfts()}
                            />
                        ) : (
                            <NftCard
                                data={nftsData}
                                showViewMoreButton={showViewMore}
                                viewMoreButtonClick={() => fetchMoreNfts()}
                            />
                        )}
                    </Grid>
                </Grid>
                <CustomModal
                    content={<CreateNewNft handleModal={handleAddNewModalClose} cluster={cluster} />}
                    visible={addNewModal}
                    title="certificate details"
                    handleClose={handleAddNewModalClose}
                />
                {/* <CustomModal
                    content={
                        <>
                            The certificate will be converted to NFT within 24 hours. You will get an email notification
                            when it's converted
                        </>
                    }
                    visible={infoModal}
                    title="NFT uploaded"
                    handleClose={handleInfoModalClose}
                /> */}
            </Container>
        </>
    );
};

export default NftList;
