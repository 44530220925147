import React from "react";
import { useHistory } from "react-router-dom";
import { UncontrolledTooltip, Button } from "reactstrap";

const CustomBackButton = ({ title = "Back", color = "secondary", disabled = false, style = {}, otherProps = {} }) => {
    const history = useHistory();

    return (
        <>
            <Button
                id="back-btn"
                color={color}
                onClick={() => history.goBack()}
                style={style}
                disabled={disabled}
                {...otherProps}
            >
                <i className="fas fa-arrow-left"></i>
            </Button>
            <UncontrolledTooltip placement="bottom" target="back-btn">
                {title}
            </UncontrolledTooltip>
        </>
    );
};

export default CustomBackButton;
